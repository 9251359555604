<template>
  <div v-if="companyKey === 'bydesignhomes'" :style="cssVariablesBinding">
    <div v-if="company" id="preloading" class="preloading">
      <div class="preloading-content">
        <img
          :src="company.logo"
          loading="lazy"
          width="Auto"
          height="Auto"
          :alt="`${company.name} Logo`"
          class="logo-preloading"
        />
      </div>
    </div>
    <div style="opacity: 0" class="page-wrapper">
      <Theme2BaseTheHeader />
      <main v-if="!activeContainer" class="main-wrapper">
        <slot />
      </main>
      <div
        v-else-if="activeContainer && activeContainer.name === 'BlogDetail'"
        class="section-blog-post-content"
        style="background-color: var(--white); max-width: 100dvw; width: 100dvw; overflow-x: hidden !important"
      >
        <div class="container-large w-100 tw-grid tw-grid-cols-12 tw-bg-white lg:tw-gap-x-10">
          <div class="tw-col-span-12 tw-px-4 lg:tw-col-span-7 xl:tw-col-span-8">
            <slot />
          </div>
          <div
            class="tw-col-span-12 tw-bg-white tw-px-4 tw-py-8 lg:tw-col-span-5 lg:tw-pt-32 xl:tw-col-span-4"
            style="z-index: 1"
          >
            <available-components-for-theme2
              :specific-components="sidebarComponents"
              key-prefix="sidebar"
            ></available-components-for-theme2>
          </div>
        </div>
      </div>
      <Theme2BaseTheFooter />
    </div>
  </div>

  <div v-else-if="companyKey === 'priceestateagentslive'" :style="cssVariablesBinding">
    <div v-if="company" id="preloading" class="preloading">
      <div class="preloading-content">
        <img
          :src="company.logo"
          loading="lazy"
          width="Auto"
          height="Auto"
          :alt="`${company.name} Logo`"
          class="logo-preloading"
        />
      </div>
    </div>
    <div style="opacity: 0" class="page-wrapper">
      <Theme2BaseTheHeader />
      <main v-if="!activeContainer" class="n-main-container">
        <slot />
      </main>
      <Theme2BaseTheFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import IndexGlobal from '~/mixins/index.global';
import LayoutsMixin from '~/mixins/layouts.mixin';
import Theme2BaseFooterOne from '~/components/theme2/base/footer/FooterOne.vue';
import { useWebsiteStore } from '~/units/website/store';
import AvailableComponentsForTheme2 from '~/components/theme2/AvailableComponentsForTheme2.vue';
import { useAppMode } from '~/composables';

export default defineNuxtComponent({
  name: 'Theme2',

  mixins: [IndexGlobal, LayoutsMixin],

  components: { AvailableComponentsForTheme2, Theme2BaseFooterOne },

  data() {
    return {
      containers: [
        {
          name: 'BLOGS_VIEW',
          classes: 'blog-wrap5',
        },
        {
          name: 'BLOG_DETAIL_VIEW',
          classes: 'blog-wrap6',
        },
      ],
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['currentPage']),

    companyKey() {
      const websiteStore = useWebsiteStore();
      return websiteStore.companyKey;
    },

    cssVariablesBinding() {
      return {
        ...this.cssVariables,
        '--nc-ternary-color': this.colors['dark-brown'],
        '--nc-ternary-bg-color': this.colors['dark-brown'],
        '--nc-secondary-bg-color': this.colors.seashell,
        '--nc-primary-color': this.colors.brown,
        '--checkbox-tick-color': 'white',
        '--nc-tenant': '#63BFBC',
        '--nc-buyer': '#f2c94c',
        '--nc-border-radius': '0',
        '--default-font': 'Inter, sans-serif',
        '--box-checked-color': this.colors['dark-brown'],
        '--checkbox-checked-color': this.colors['dark-brown'],
        '--default-border-color': '#aca39a',
        '--form-errors-background-color': this.colors['dark-brown'],
        '--form-errors-text-color': this.colors.seashell,
        '--default-title-font': 'Ivy Mode, sans-serif',

        '--nc-popup-background-color': 'white',

        '--akya-text-color': this.colors['dark-brown'],
        '--akya-background-color': this.colors.seashell,
        '--akya-confirm-button-text-color': 'white',
        '--akya-confirm-button-background-color': this.colors['brown'],

        '--nc-primary-button-text-color': 'white',
      };
    },
  },

  head(context) {
    const websiteStore = useWebsiteStore();
    const appMode = useAppMode();

    if (appMode.server && context.ssrContext) {
      const tagScripts = (() => {
        if (process.env.APP_STAGE === 'production') {
          return [
            ...websiteStore.googleTagsScripts,
            {
              key: 'company-analytics',
              type: 'text/javascript',
              src: `/theme2/js/${websiteStore.companyKey}.js`,
              async: false,
            },
          ];
        }
        return [];
      })();

      return {
        link: [
          {
            href: '/theme2/css/bootstrap.min.css',
            rel: 'stylesheet',
            hid: 'css/bootstrap.min.css',
            type: 'text/css',
          },
          {
            href: '/theme2/css/normalize.css',
            rel: 'stylesheet',
            hid: 'css/normalize.css',
            type: 'text/css',
          },
          {
            href: '/theme2/css/webflow.css',
            rel: 'stylesheet',
            hid: 'css/webflow.css',
            type: 'text/css',
          },
          {
            href: '/theme2/css/webflow-extra.css',
            rel: 'stylesheet',
            hid: 'css/webflow-extra.css',
            type: 'text/css',
          },
          {
            href: `/theme2/css/${websiteStore.companyKey}.css`,
            rel: 'stylesheet',
            key: `css/${websiteStore.companyKey}.css`,
            type: 'text/css',
          },
        ],
        script: [
          {
            hid: 'jquery-3.5.1',
            src: `/theme2/js/jquery-3.5.1.min.dc5e7f18c8.js`,
            body: true,
            async: false,
          },
          {
            hid: 'webflow',
            src: `/theme2/js/theme2.js`,
            body: false,
            type: 'text/javascript',
          },
          {
            id: 'webflow',
            src: `/theme2/js/webflow.js`,
            body: true,
            type: 'text/javascript',
            async: true,
          },
          ...tagScripts,
        ],
        htmlAttrs: {
          'data-wf-page': '657c40fbaadb6b6d89606d42',
          'data-wf-site': '657c40f9aadb6b6d8960698b',
        },
      };
    }
  },
});
</script>

<style>
main > .section-services-content.content-box:first-child {
  padding-top: 8rem !important;
}

iframe {
  width: 100% !important;
  max-width: 100% !important;
  margin: 10px 0 10px 0;
}
</style>
